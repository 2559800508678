.card-search__wrapper {
    margin: 1.5rem;
    padding: 3rem;
    box-shadow: 1px 1px 8px #c9ccd2;
}

.card-search {
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-search__title {
    font-weight: 800;
    color: #a91c22;
    font-size: 2rem;
}

.card-search__form {
    margin-top: 1.5rem;
    outline: none;
    outline-offset: none;
}

.card-search__error {
    color: red;
    font-size: 0.8rem;
    text-align: left;
}

.card-search__button {
    background-color: #d8dadf !important;
    border-color: #d8dadf !important;
    color: black !important;
    font-weight: 500;
    margin-top: 1rem;
}

.card-search__footer {
    text-align: center;
    font-style: italic;
    font-size: 0.9rem;
}

@media only screen and (min-width: 1200px) { 
    .card-search__wrapper {
        margin-top: 1rem;
        margin-bottom: 1rem;
        margin-left: 0.8rem;
        margin-right: 0.8rem;
    }
    
    .card-search__title {
        font-weight: 800 !important; 
        color: #a91c22;
        font-size: 2.2rem !important;
    }

}

@media only screen and (max-width: 1200px) { 
    .card-search__wrapper {
        margin-top: 1rem;
        margin-bottom: 1rem;
        margin-left: 0.8rem;
        margin-right: 0.8rem;
    }

    .card-search__title {
        font-weight: 800 !important; 
        color: #a91c22;
        font-size: 2rem !important;
        padding-right: 0 !important;
    }
}

@media only screen and (max-width: 768px) { 
    .card-search__wrapper {
        margin-top: 1rem;
        margin-bottom: 1rem;
        margin-left: 0.8rem;
        margin-right: 0.8rem;
    } 

    .card-search__title {
        font-weight: 800 !important; 
        color: #a91c22;
        font-size: 1.8rem !important;
    }
}

@media only screen and (max-width: 576px) {
    .card-search__wrapper {
        margin: 0.8rem;
        margin-top: 0.4rem;
        margin-bottom: 0.4rem;
    } 

    .card-search__title {
        font-weight: 800 !important; 
        color: #a91c22;
        font-size: 1.5rem !important;
    }

    .card-search__button {
        font-size: 0.87rem !important;
        margin-top: 0.5rem !important;
    }

    .card-search__form {
        font-size: 0.87rem !important;
    }

    .card-search__footer {
        font-size: 0.8rem !important;
    }
}