.card-wrapper {
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 0.8rem;
    margin-right: 0.8rem;
    display: flex;
    flex-direction: row !important;
    background-color: transparent !important;
}

.card-wrapper__delivery-info {
    box-shadow: 1px 1px 8px #c9ccd2;
    margin-right: 5px;
    background-color: #fff;
    border-radius: 0.25rem;
    padding-left: 2rem !important;
    padding-right: 2rem !important;
}

.card-wrapper__delivery-status {
    box-shadow: 1px 1px 8px #c9ccd2;
    margin-left: 5px;
    background-color: #fff;
    border-radius: 0.25rem;
    display: flex;
    flex-direction: column;
    padding: 0 !important;
    overflow: hidden;
    justify-content: space-between;
    width: 155px;
}

.card-wrapper__delivery-status__title {
    min-height: 2.5rem;
    color: #222;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
}

.card-wrapper__delivery-status__body {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.8rem;
}

.card-wrapper__delivery-status__footer {
    padding: 1rem;
    padding-top: 10px;
}

.card-wrapper__delivery-status__footer-button {
    width: 100%;
    background-color: #E1E3E7 !important;
    border-color: #E1E3E7 !important;
    color: black !important;
}

.card-text {
    font-weight: 800;
    color: #a91c22;
}

.card-text__wrapper {
    display: flex;
    justify-content: space-between;
}

.card-text__wrapper div {
    width: 40px;
    height: 40px;
    border-radius: 100px;
    box-shadow: 1px 1px 8px #c9ccd2;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.card-text-icon {
    float: right;
}

.card-progress {
    height: 2rem !important;
}

.card-date-wrapper__checker {
    height: 2rem;
    width: 100%;
    margin-top: 0.2rem;
    text-align: right;
}

.card-date-wrapper__checker span {
    font-size: 0.9rem;
    font-weight: 600;
    position: relative;
}

.card-date-wrapper__non-checker {
    height: 2rem;
    width: 100%;
    margin-top: 0.2rem;
}

.card-date-wrapper__non-checker span {
    font-size: 0.9rem;
    font-weight: 600;
    position: relative;
}

.card-status__chinese {
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.card-status__english {
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.card-button__order {
    width: 100% !important;
    margin-bottom: 0.3rem !important;
    margin-top: 2rem !important;
    background-color: #fff !important;
    color: black !important;
    font-weight: 500 !important;
    border-color: #d8dadf !important;
    border-width: 2px !important;
}

.card-button__waybill-wrapper {
    display: flex;
    justify-content: center;
}

.card-button__waybill-unknown {
    text-align: center;
    font-size: 0.8rem;
    font-style: italic;
    color: red;
}

.card-button__waybill-known {
    width: 100%;
    background-color: #d8dadf !important;
    border-color: #d8dadf !important;
    color: black !important;
    font-weight: 500 !important;
}

.card-identity-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
}

.card-identity {
    display: flex;
    width: 576px;
    border: 1px solid #dee2e6;
    padding-left: 1rem;
    padding-right: 1rem;
}

.font-weight-600 {
    font-weight: 600;
}

.tracking-results {
    --mask: linear-gradient(to bottom, 
    rgba(0,0,0, 1) 0,   rgba(0,0,0, 1) 40%, 
    rgba(0,0,0, 0) 95%, rgba(0,0,0, 0) 0
    ) 100% 50% / 100% 100% repeat-x;
    -webkit-mask: var(--mask); 
    mask: var(--mask);
}

.card-tracking-status-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 0.9rem;
}

.card-tracking-status {
    display: flex;
    justify-content: space-between;
    display: flex;
    align-items: center;
}

.card-tracking-status__icon-wrapper {
    width: 40px;
    height: 40px;
    box-shadow: 1px 1px 8px #c9ccd2;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
}

.card-tracking-status__text {
    font-weight: 700;
    font-size: 0.9rem;
    margin-left: 8px;
    margin-right: 3px;
    line-height: 1.1;
}

.card-tracking-status-line {
    height: 2px;
    width: 65px;
    border-radius: 100px;
    margin-left: 2px;
    margin-right: 2px;
}

.card-tracking-status-circle {
    height: 8px;
    width: 8px;
    border-radius: 100px;
    margin-left: 2px;
    margin-right: 2px;
    position: relative;
}

.card-tracking-status-circle div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    text-align: center;
    left: -46px;
    font-size: 0.75rem;
    top: 11px;
    line-height: 1;
}

.card-express-wrapper {
    display: flex;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin-top: 5px;
}

.card-express-box {
    margin-right: 1.2rem;
}

.card-express {
    font-size: 0.6rem;
    color: #a6a6a6;
}   

.card-express-copy-box {
    position: relative;
    display: flex;
    justify-content: center;
}

.card-express-copy {
    position: absolute;
    top: -1.8rem;
    background-color: black;
    padding: 5px;
    color: white;
    z-index: 2;
    border-radius: 0.25rem;
}

.card-express-copy2 {
    position: absolute;
    margin-top: -1.7rem;
    background-color: black;
    padding: 5px;
    color: white;
    z-index: 2;
    border-radius: 0.25rem;
}

.card-express-copy2-box {
    display: flex;
    justify-content: center;
}

.card-express-square2 {
    position: absolute;
    height: 15px;
    width: 15px;
    left: 37%;
    top: 6px;
    background-color: black;
    transform: rotate(45deg);   
    z-index: -1; 
}

.card-express-square {
    position: absolute;
    height: 15px;
    width: 15px;
    background-color: black;
    top: -1.4rem;
    transform: rotate(45deg);
}

.card-express-value {
    display: flex;
    align-items: center;
    font-size: 0.75rem;
    line-height: 1;
    font-weight: 500;
}

#tracking-number {
    overflow-wrap: anywhere;
}

.card-express-icon {
    margin-left: 2px;
    cursor: pointer;
}

.card-wrapper__delivery-status-wrapper {
    display: flex;
}

.card-wrapper__delivery-status-box {
    margin-right: 1rem;
}



@media only screen and (max-width: 576px) {
    .card-wrapper {
        padding: 0.4rem;
        margin: 0.4rem;
        margin-left: 0.8rem;
        margin-right: 0.8rem;
    }

    .card-text {
        margin-bottom: 0.3rem;
    }

    .card-title {
        font-size: 1.03rem !important;
    }

    .card-date-wrapper__checker {
        margin-top: 0;
    }

    .card-date-wrapper__checker span {
        font-size: 0.78rem;
    }

    .card-date-wrapper__non-checker span {
        font-size: 0.78rem;
    }

    .card-status__chinese {
        font-size: 0.87rem;
        margin-top: 0.5rem;
    }

    .card-status__english {
        font-size: 0.87rem;
    }

    .card-button__order {
        font-size: 0.87rem !important;
        margin-top: 1rem !important;
    }

    .card-button__waybill-known {
        font-size: 0.87rem !important;
    }

    .card-button__waybill-unknown {
        font-size: 0.73rem;
    }
}

@media only screen and (min-width: 576px) {
    .card-wrapper {
        margin-top: 10px;
        margin-bottom: 10px;
        margin-left: 0.8rem;
        margin-right: 0.8rem;
    }
    
    .card-text {
        margin-bottom: 0.6rem;
    }

    .card-status__chinese {
        font-size: 0.9rem;
        margin-bottom: 1.5rem;
    }

    .card-status__english {
        font-size: 0.9rem;
        margin-bottom: 1.5rem;
    }

    .card-button__wrapper {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .card-title {
        font-size: 1.15rem;
    }

    .card-button__order {
        width: 435px !important;
        font-size: 0.9rem !important;
        margin-top: 1.5rem !important;
    }

    .card-button__waybill-known {
        font-size: 0.9rem !important;
    }

    .card-button__waybill-wrapper {
        width: 435px;
    }
}

@media only screen and (min-width: 768px) {
    .card-button__wrapper {
        display: flex;
        flex-direction: row;
    }

    .card-text {
        margin-bottom: 1rem;
    }

    .card-button__order {
        width: 50% !important;
        font-size: 1rem !important;
        margin-top: 2rem !important;
    }

    .card-button__waybill-known {
        font-size: 1rem !important;
    }

    .card-status__chinese {
        font-size: 1rem;
        margin-top: 1rem;
    }

    .card-status__english {
        font-size: 1rem;
    }

    .card-button__waybill-wrapper {
        width: 50% !important;
        margin-top: 2rem;
        margin-bottom: 0.3rem;
        margin-left: 10px;
    }
}

@media only screen and (min-width: 1200px) {
    .card-title {
        font-size: 1.25rem;
    }

    .card-button__wrapper {
        display: flex;
        justify-content: center;
    }

    .card-button__order {
        width: 350px !important;
    }

    .card-button__waybill-wrapper {
        width: 350px !important;
    }
}

@media only screen and (max-width: 1200px) {
    .card {
        background-color: #FFF !important;
    }

    .card-wrapper {
        flex-direction: column !important;
        box-shadow: 1px 1px 8px #c9ccd2;
        overflow: hidden;
    }

    .card-wrapper__delivery-info {
        box-shadow: none;
        margin-right: 0px;
        background-color: #fff;
    }
    
    .card-wrapper__delivery-status {
        box-shadow: none;
        margin-left: 0px;
        background-color: #fff;
    }

    .card-title {
        padding-right: 60px;
    }
    
    .card-identity-wrapper {
        flex-direction: column;
        align-items: center;
    }

    .card-express-wrapper {
        width: 576px;
    }
}

@media only screen and (max-width: 768px) {
    .card-identity-wrapper {
        margin-top: 1rem;
    }

    .card-identity {
        width: 450px;
    }

    .card-title {
        font-size: 1.15rem !important;
    }

    .card-button__order {
        margin-top: 0px !important;
    }

    .card-express-wrapper {
        width: 450px;
    }
}

@media only screen and (max-width: 576px) {
    .card-title {
        font-size: 1.03rem !important;
    }

    .card-wrapper__delivery-info {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }

    .card-identity {
        padding-left: 2rem;
        padding-right: 2rem;
        width: 100%;
        justify-content: space-between;
    }

    .card-identity-wrapper {
        margin-top: 0.5rem;
    }

    .card-status__chinese {
        line-height: 1.2;
    }

    .card-status__english {
        line-height: 1.2;
    }

    .card-tracking-status-wrapper {
        margin-top: 0.6rem;
        width: 100%;
    }

    .card-tracking-status-line {
        flex: 1;
        margin-left: 4px;
        margin-right: 4px;
    }

    .card-tracking-status {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .card-button__waybill-wrapper {
        width: 100%;
    }

    .card-express-wrapper {
        width: 100%;
        padding-left: 2.5rem;
        padding-right: 2.5rem;
    }
}

@media only screen and (max-width: 440px) {
    .card-identity {
        padding-left: 0.2rem;
        padding-right: 0.2rem;
    }

    .card-express-wrapper {
        padding-left: 0.7rem;
        padding-right: 0.7rem;
    }
}