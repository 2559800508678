.language-right__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.language-right {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
}

#offcanvas-delay {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    height: 100% !important;
    border-left: none;
}

.offcanvas-delay__h1 {
    font-weight: 600;
    margin-bottom: 2rem;
}

@media only screen and (max-width: 475px) {
    #offcanvas-delay {
        width: 100vw !important;
    }
    
}