.card-info__wrapper {
    margin-top: 10px;
    margin-bottom: 0.4rem;
    margin-left: 0.8rem;
    margin-right: 0.8rem;
    box-shadow: 1px 1px 8px #c9ccd2;
    overflow: hidden;
}

.card-info__cursor-pointer {
    cursor: pointer;
}

.card-info__wrapper div {
    font-size: 0.9rem;
}

.card-info__icon {
    font-size: 1.1rem;
    margin-right: 5px;
    color: #25D366;
}

.footer {
    text-align: center;
    font-style: italic;
    font-size: 0.8rem;
}

.margin-top--5 {
    margin-top: -5px;
}

#heeloo, #delay-orange, #delay-red {
    padding-left: 1.5rem !important;
}

#delay-orange, #delay-red {
    padding-right: 0.2rem !important;
}

#lucky-wrapper {
    overflow: hidden;
    background-color: #fff !important;
    box-shadow: 1px 1px 8px #c9ccd2;
}

@media only screen and (max-width: 576px) {
    .card-info__wrapper {
        margin-top: 0.4rem;
    }

    #heeloo div, #delay-orange div, #delay-red div {
        font-size: 0.81rem !important;
    }
    
    .card-body {
        padding-top: 0.6rem !important;
        padding-bottom: 0.6rem !important;
    }
}

@media only screen and (max-width: 768px) {
    #heeloo div, #delay-orange div, #delay-red div {
        font-size: 0.85rem;
    }
    
}
