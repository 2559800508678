.progress-dots__wrapper {
    display: flex;
    justify-content: center;
    margin-top: 1.2rem;
}

.progress-dots {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    align-self: center;
    width: 768px;
}

.progress-dots__item {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color: red;
    position: relative;
    z-index: 11;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3rem;
}

.progress-dots__item span {
    position: absolute;
    display: block;
    font-size: .75rem;
    bottom: -16px;
    line-height: 1;
    width: 120px;
    text-align: center;
}

.progress-dots__item-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.progress-dots__item-line {
    width: 100px;
    height: 3px;
    border-radius: 2px;
}


@media only screen and (max-width: 1200px) {
    .progress-dots {
        width: 576px;
    }

    .progress-dots__item-line {
        width: 72px;
    }
}

@media only screen and (max-width: 768px) {
    .progress-dots__wrapper {
        display: none;
    }
}