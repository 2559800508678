.waybill-info__wrapper {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
}

.waybill_info__wrapper-fade {
    --mask: linear-gradient(to bottom, 
        rgba(0,0,0, 1) 0,   rgba(0,0,0, 1) 40%, 
        rgba(0,0,0, 0) 95%, rgba(0,0,0, 0) 0
    ) 100% 50% / 100% 100% repeat-x;
    -webkit-mask: var(--mask); 
    mask: var(--mask);
}

.waybill-info__wrapper-opened {
    display: flex;
    justify-content: center;
}

.waybill-info {
    width: 768px;
}

.waybill-info__content {
    display: flex;
}

.waybill-info__datetime-wrapper {
    width: 18%;
    text-align: right;
    padding-right: 1rem;
    padding-top: 4px;
}

.waybill-info__date {
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1;
    margin-bottom: 3px;
}

.waybill-info__time {
    font-size: 0.72rem;
    line-height: 1;
    color: #6f7982;
    letter-spacing: 0.3px;
}

.waybill-info__text-wrapper {
    width: 82%;
    border-left: 1px solid #dee2e6;
    font-size: 0.9rem;
    padding-left: 1rem;
    padding-bottom: 2rem;
    position: relative;
}

.waybill-info__text {
    overflow-wrap: anywhere;
}

.waybill-info__circle {
    width: 12px;
    height: 12px;
    position: absolute;
    background-color: #C5C9D0;
    border-radius: 100px;
    top: 4px;
    left: -6px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 20;
}

.waybill-info__location-wrapper {
    display: flex; 
    align-items: center;
    color: #6f7982;
}

.waybill-info__pod-feedback-wrapper {
    display: flex;
    flex-wrap: wrap;
}

.waybill-info__location {
    font-size: 0.65rem;
}

.waybill-info__pod {
    display: flex;
    flex-direction: column;
    margin-top: 3px;
}

.waybill-info__feedback-button {
    background-color: rgba(21, 44, 255, 0.1);
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    padding-top: 0.32rem;
    padding-bottom: 0.32rem;
    margin-right: 4px;
    cursor: pointer;
    align-self: flex-start;
    font-size: 0.76rem;
    border-radius: 0.25rem;
    font-weight: 600;
}

.waybill-info__pod-button {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    padding-top: 0.32rem;
    padding-bottom: 0.32rem;
    background-color: rgb(191, 242, 155);
    cursor: pointer;
    margin-right: 4px;
    align-self: flex-start;
    font-size: 0.76rem;
    border-radius: 0.25rem;
    font-weight: 600;
}

.waybill-info__review-button {
    padding-left: 0.38rem;
    padding-right: 0.38rem;
    padding-top: 0.32rem;
    padding-bottom: 0.32rem;
    background-color: #FFBDBD;
    cursor: pointer;
    align-self: flex-start;
    font-size: 0.76rem;
    border-radius: 0.25rem;
    color: #E21818;
}

.waybill-info__box-white {
    width: 12px;
    height: 12px;
    position: absolute;
    background-color: #fff;
    z-index: 19;
    left: -6px;
    top: -5px;
}

.waybill-info__circle-small {
    width: 8px;
    height: 8px;
    border-radius: 100px;
    background-color: white;
}

@media only screen and (max-width: 1200px) {
    .waybill-info__datetime-wrapper {
        width: 25%;
    }
    
    .waybill-info__text-wrapper {
        width: 75%;
    }

    .waybill-info__wrapper {
        margin-top: 1rem;
    }
}

@media only screen and (max-width: 768px) {
    .waybill-info__wrapper {
        margin-top: 1.2rem;
    }
    
    .waybill-info__text-wrapper {
        font-size: 0.87rem;
    }
}

@media only screen and (max-width: 576px) {
    .waybill-info__datetime-wrapper {
        width: 28%;
    }

    .waybill-info__text-wrapper {
        padding-bottom: 1.1rem;
        width: 72%;
    }

    .waybill-info__date {
        font-size: 0.7rem;
    }

    .waybill-info__time {
        font-size: 0.63rem;
    }

    .waybill-info__text {
        font-size: 0.81rem;
        line-height: 1.3;
        padding-top: 2px;
    }
}

