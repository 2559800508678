body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.form-control:focus{
  border-color: #ced4da !important;
  box-shadow: none !important;
}

button:focus {
  border-color: #ced4da !important;
  box-shadow: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.card {
  border: none !important;
}

#heeloo:before {
  content: '';
  position: absolute;
  top: 0; left: 0;
  width: 9px;
  height: 110%;
  background-color: rgb(25,135,84);
}

#delay-orange:before {
  content: '';
  position: absolute;
  top: 0; left: 0;
  width: 9px;
  height: 110%;
  background-color: orange;
}

#delay-red:before {
  content: '';
  position: absolute;
  top: 0; left: 0;
  width: 9px;
  height: 110%;
  background-color: red;
}


#warning-delivery:before {
  content: '';
  position: absolute;
  top: 0; left: 0;
  width: 9px;
  height: 110%;
  background-color: rgb(255, 15, 15);
}

#lucky-orange:before {
  content: '';
  position: absolute;
  top: 0; left: 0;
  width: 9px;
  height: 110%;
  background-color: rgb(255, 165, 0);
}

.flex-container__wrapper {
  position: relative;
}

.flex-container__second__image {
  width: 100%;
}

@media only screen and (max-width: 576px) {
  .flex-container__bg {
    display: none;
  }

  .circular-progress {
    position: fixed;
    right: 10px;
    top: 10px;
  }

  .personal-container {
    border-radius: 0.25rem;
    margin: 0.4rem 0.8rem !important;
    margin-bottom: 0 !important;
    padding: 0.8rem !important;
  }

  .item-container {
    overflow: hidden;
    border-radius: 0.25rem;
    margin: 0.4rem 0.8rem !important;
  }

  .item_container__right__item-price {
    font-size: 0.8rem;
  }

  .item_container__right__item-desc {
    font-size: 0.8rem;
  }
}

@media only screen and (max-width: 768px) {
  .flex-container__second__personal::after {
    display: block;
    content: ". . .";
    font-weight: 600;
    width: 100%;
    text-align: center;
    color: #a6a6a6;
  }

  .personal-container__add-hp-row {
    display: flex;
  }

  .personal-container__address {
    width: 65%;
  }

  .personal-container__hpNumber {
    width: 35%;
  }
}

@media only screen and (min-width: 576px) {
  .flex-container {
    display: flex;
    flex-direction: row;
    /* height: 100vh; */
    width: 100%;
    justify-content: center;
  }

  .flex-container__second {
    width: 576px;
  }

  .personal-container {
    border-radius: 0.25rem;
    margin-bottom: 0 !important;
  }

  .item-container {
    overflow: hidden;
    border-radius: 0.25rem;
    margin-top: 0.4rem !important;
  }

  .flex-container__second__image {
    width: 576px;
  }

  .flex-container__bg {
    height: 219.208px;
    position: absolute;
    width: 100%;
    background-image: url("https://ddlive-qrcode.s3.ap-southeast-1.amazonaws.com/%E4%BA%8B%E4%BA%8B%E9%83%BD%E5%A6%82%E6%84%8F%EF%BC%8C%E5%85%94%E5%85%94%E7%9A%86%E5%90%90%E6%B0%A3+(4).jpg");
    background-size: cover;
    background-position: center;
    filter: blur(8px);
    -webkit-filter: blur(8px);
  }

  .circular-progress {
    position: fixed;
    right: calc((100vw - 576px)/2 + 7px);
    top: 10px;
  }
}

@media only screen and (min-width: 768px) {
  .flex-container {
    display: flex;
    flex-direction: row;
    /* height: 100vh; */
    width: 100%;
    justify-content: center;
  }
  
  .flex-container__second {
    display: flex;
    width: 768px;
    flex-wrap: wrap;
  }

  .flex-container__second__image {
    width: 768px !important; 
  }

  .flex-container__second__personal {
    width: 267px;
  }

  .flex-container__second__items-list {
    width: 501px;
  }
  
  .flex-container__bg {
    height: 292.281px;
    position: absolute;
    width: 100%;
    background-image: url("https://ddlive-qrcode.s3.ap-southeast-1.amazonaws.com/%E4%BA%8B%E4%BA%8B%E9%83%BD%E5%A6%82%E6%84%8F%EF%BC%8C%E5%85%94%E5%85%94%E7%9A%86%E5%90%90%E6%B0%A3+(4).jpg");
    background-size: cover;
    background-position: center;
    filter: blur(8px);
    -webkit-filter: blur(8px);
  }

  .item-container {
    overflow: hidden;
    border-radius: 0.25rem;
    margin-top: 0.8rem !important;
  }

  .personal-container__hpNumber {
    padding-left: 0 !important;
  }

  .circular-progress {
    position: fixed;
    right: calc((100vw - 768px)/2 + 7px);
    top: 10px;
  }
}

@media only screen and (min-width: 1200px) {
  .flex-container {
    display: flex;
    flex-direction: row;
    /* height: 100vh; */
    width: 100%;
    justify-content: center;
  }
  
  .flex-container__second {
    display: flex;
    width: 1200px;
    flex-wrap: wrap;
  }

  .flex-container__second__image {
    width: 1200px !important; 
  }

  .flex-container__second__personal {
    width: 417px;
  }

  .flex-container__second__items-list {
    width: 783px;
  }
  
  .flex-container__bg {
    height: 456.689px;
    position: absolute;
    width: 100%;
    background-image: url("https://ddlive-qrcode.s3.ap-southeast-1.amazonaws.com/%E4%BA%8B%E4%BA%8B%E9%83%BD%E5%A6%82%E6%84%8F%EF%BC%8C%E5%85%94%E5%85%94%E7%9A%86%E5%90%90%E6%B0%A3+(4).jpg");
    background-size: cover;
    background-position: center;
    filter: blur(8px);
    -webkit-filter: blur(8px);
  }

  .item-container {
    overflow: hidden;
    border-radius: 0.25rem;
    margin-top: 0.8rem !important;
  }

  .personal-container__hpNumber {
    padding-left: 0 !important;
  }

  .circular-progress {
    position: fixed;
    right: calc((100vw - 1200px)/2 + 7px);
    top: 10px;
  }
}

.circular-progress {
  position: fixed;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  z-index: 999;
  transition: stroke-dashoffset 1s;
}

.circular-progress:before {
  content: "";
  position: absolute;
  height: 65%;
  width: 65%;
  background-color: #ffffff;
  border-radius: 50%;
}

.value-container {
  position: relative;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 800;
  color: black;
}