* {
  font-family: 'Poppins', sans-serif;
}

html {
  background-color: #f0f2f5;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.app-wrapper__header-wrapper {
  position: relative;
  overflow: hidden;
  box-shadow: 1px 2px 8px #888f9c;
}

.app-wrapper__header-bg {
  background-image: url("https://ddlive-qrcode.s3.ap-southeast-1.amazonaws.com/%E4%BA%8B%E4%BA%8B%E9%83%BD%E5%A6%82%E6%84%8F%EF%BC%8C%E5%85%94%E5%85%94%E7%9A%86%E5%90%90%E6%B0%A3+(4).jpg");
  background-size: cover;
  background-position: center;
  filter: blur(8px);
  -webkit-filter: blur(8px);
  width: 100%;
  position: absolute;
}

.app-wrapper__header {
  display: flex;
  justify-content: center;
  /* background-image: url("https://ddlive-qrcode.s3.ap-southeast-1.amazonaws.com/%E4%BA%8B%E4%BA%8B%E9%83%BD%E5%A6%82%E6%84%8F%EF%BC%8C%E5%85%94%E5%85%94%E7%9A%86%E5%90%90%E6%B0%A3+(4).jpg");
  background-size: cover;
  background-position: center;
  filter: blur(8px);
  -webkit-filter: blur(8px); */
}

.app-wrapper__data-wrapper {
  display: flex;
  justify-content: center;
}

.language-button {
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
  margin-left: 0.8rem;
  margin-right: 0.8rem;
  padding: 0.2rem;
  background-color: #fff;
  box-shadow: 1px 1px 8px #c9ccd2;
  width: fit-content;
  border-radius: 0.25rem;
  cursor: pointer;
  font-size: 0.83rem;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.language-button div {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}

.language-plus {
  display: none;
}

@media only screen and (max-width: 576px) {
  .app-wrapper__header__image {
    width: 100%;
    z-index: 999;
  }

  .app-wrapper__data {
    width: 100%;
  }

  .language-button {
    width: auto;
  }
}

@media only screen and (min-width: 576px) {
  .app-wrapper__header__image {
    width: 576px;
    z-index: 999;
  }

  .app-wrapper__header-bg {
    height: 219.208px;
  }

  .app-wrapper__data {
    width: 576px;
  }
  
  .language-button {
    z-index: 1039;
    position: absolute;
    margin-top: -46px;
    font-size: 0.9rem;
  }

  .offcanvas {
    width: 576px;
  }

  .offcanvas-top {
    left: calc((100vw - 576px)/2) !important;
  }
}

@media only screen and (min-width: 768px) {
  .app-wrapper__header__image {
    width: 768px;
    z-index: 999;
  }
  
  .app-wrapper__header-bg {
    height: 292.281px;
  }

  .app-wrapper__data {
    width: 768px;
  }
}

@media only screen and (min-width: 1200px) {
  .app-wrapper__header__image {
    width: 1200px;
    z-index: 999;
  }

  .app-wrapper__header-bg {
    height: 456.689px;
  }

  .app-wrapper__data {
    width: 1200px;
  }
}    

.thumbs-wrapper {
  display: none;
}

.carousel-status {
  display: none;
}

.control-arrow {
  display: none;
}

.carousel-indicator {
    transition: opacity .25s ease-in;
    filter: alpha(opacity=30);
    box-shadow: 1px 1px 2px rgb(0 0 0 / 90%);
    background: #fff;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    cursor: pointer;
    display: inline-block;
    margin: 0 8px;
}

.dot {
  opacity: .3;
}

.dot-selected {
  opacity: 1;
}

.offcanvas {
  border-bottom-left-radius: 1.9rem;
  border-bottom-right-radius: 1.9rem;
  height: fit-content !important;
}

.offcanvas-title {
  font-size: 1.25rem;
}

.offcanvas-header {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.offcanvas-body {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
  padding-bottom: 2rem !important;
}

.lang {
  padding: 1rem;
  border: 1px solid #d8dadf;
  border-radius: 0.5rem;
  margin-top: 10px;
  text-align: center;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
    .offcanvas-body {
      padding-top: 0px !important;
    }
}

@media only screen and (max-width: 576px) {
    .offcanvas {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      border-top-left-radius: 1.9rem;
      border-top-right-radius: 1.9rem;
      height: fit-content !important;
    }

    .offcanvas-body {
      padding: 1rem !important;
      padding-top: 0rem !important;
    }

    .lang {
      margin-top: 0.3rem;
      padding: 0.5rem 0.75rem;
      font-size: 0.87rem;
    }

    .offcanvas-title {
      font-size: 1.1rem !important;
    }

    .language-plus {
      display: block;
    }
}