.identity-wrapper {
    display: flex;
    width: 100%;
    border: 1px solid #dee2e6;
    padding: 0.5rem;
    border-radius: 0.25rem;
    margin-bottom: 5px;
    align-items: center;
}

.identity-wrapper__img {
    width: 40px;
}

.identity-wrapper__info-wrapper {
    margin-left: 8px;
}

.identity-wrapper__info-type {
    font-size: 0.6rem;
    color: #a6a6a6;
}

.identity-wrapper__checker-image-logo {
    font-size: 12px;
    margin-left: 3px;
    color: #146C94;
    cursor: pointer;
}

.blink_me {
    animation: 1s linear 5 blinker;
}
  
@keyframes blinker {  
    50% { opacity: 0; }
}

.identity-wrapper__info-name {
    font-size: 0.85rem;
    line-height: 1;
    font-weight: 500;
}

.identity-wrapper__info-time {
    font-size: 0.66rem;
    margin-top: 2px;
    line-height: 1.1;
}

.identity-wrapper__no-user {
    height: 5px;
    background-color: #dee2e6;
    margin-top: 2px;
    border-radius: 100px;
}

.width-no-user-110 {
    width: 110px;
}

.width-no-user-70 {
    width: 70px;
}

@media only screen and (max-width: 576px) {
    .identity-wrapper {
        min-width: 10%;
        width: auto;
    }

    .width-no-user-110 {
        width: 75px;
    }

    .width-no-user-70 {
        width: 50px;
    }
}