.personal-container {
    background-color: white;
    margin: 0.8rem;
    box-shadow: rgb(201 204 210) 1px 1px 8px;
    padding: 1rem;
    line-height: 1.3rem;
    position: -webkit-sticky;
    position: sticky;
    top: 0.8rem;
    font-size: 0.9rem;
}

.personal-container__orderId {
    color: rgb(169, 28, 34);
    text-align: right;
    font-weight: 700;
    font-size: 1.1rem;
    margin-bottom: 10px;
}

.personal-container__name {
    font-weight: 600;
    text-decoration: underline;
    margin-bottom: 7px;
}

.personal-container__add-hp-row {
    margin-bottom: 7px;
}

.personal-container__address {
    margin-bottom: 7px;
}

.personal-container__hpNumber {
    padding-left: 1rem;
}

.personal-container__remark {
    background-color:  #FBF46D
}



.item-container {
    min-width: 0;
    margin: 0.8rem;
    background-color: white;
    box-shadow: rgb(201 204 210) 1px 1px 8px;
    display: flex;
    font-size: 0.9rem;
}

.item_container__left {
    width: 16.7%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
}

.item_container__right {
    width: 83.3%;
    padding: 0.5rem;
    display: flex;
    position: relative;
}

.item_container__right__item-name-container {
    width: 42%;
    padding-right: 5px;
}

.item_container__right__item-name {
    font-weight: 600;
    line-height: 1.2;
    margin-top: 7px;
}

.item_container__right__item-details-container {
    width: 58%;
    /* To prevent overlapping with the quantity circle */
    padding-right: 40px;
}

.item_container__right__item-desc {
    font-style: italic;
    line-height: 1.2;
}

.item_container__right__item-price {
    margin-bottom: 7px;
}

.item_container__right__item-qty-container {
    position: absolute;
    right: 5px;
    bottom: 5px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
}

.item_container__right__item-qty-container div {
    font-weight: 700;
    font-size: 1.1rem;
}

.item-title {
    font-size: 0.7rem;
    color: #a6a6a6;
    margin-bottom: 3px;
    line-height: 1;
    display: block;
}

@media only screen and (min-width: 768px) {
    .personal-container {
        margin-right: 0.4rem;
    }
    
    .item-container {
        margin-left: 0.4rem;  
    }
}

.notfound {
    background-color: white;
    margin: 0.8rem;
    padding: 2.3rem;
    box-shadow: rgb(201 204 210) 1px 1px 8px;
    text-align: center;
    border-radius: 0.25rem;
}

.notfound-text {
    font-size: 5rem;
    font-weight: 800;
    line-height: 1;
    margin-bottom: 1rem;
}

.notfound-text__chi {
    font-size: 0.9rem;
}

.notfound-text__eng {
    font-size: 0.9rem;
}

.order-footer {
    font-size: 0.9rem;
    text-align: center;
    display: block;
    width: 100%;
    text-align: center;
    font-style: italic;
    color: #a6a6a6;
}

.card-spinner__wrapper {
    margin: 1.5rem;
    margin-top: 0.8rem;
    padding: 6rem;
    box-shadow: 1px 1px 8px #c9ccd2;
    width: 100%;
}

.card-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (min-width: 1200px) {
    .card-spinner__wrapper, .notfound {
        margin-top: 1rem;
        margin-bottom: 1rem;
        margin-left: 0.8rem;
        margin-right: 0.8rem;
        width: calc(1200px - 1.6rem)
    }
}

@media only screen and (max-width: 1200px) {
    .card-spinner__wrapper, .notfound {
        margin-top: 1rem;
        margin-bottom: 1rem;
        margin-left: 0.8rem;
        margin-right: 0.8rem;
        width: calc(768px - 1.6rem)
    }
}

@media only screen and (max-width: 768px) {
    .card-spinner__wrapper, .notfound {
        width: calc(576px - 1.6rem)
    }
}

@media only screen and (max-width: 576px) {
    .card-spinner__wrapper, .notfound {
        margin: 0.8rem;
        margin-top: 0.4rem;
        margin-bottom: 0.4rem;
        width: calc(100vw - 1.6rem);
    }

    .notfound-text__chi {
        font-size: 0.8rem;
    }

    .notfound-text__eng {
        font-size: 0.8rem;
    }
}
